export const Faq = () => {
    return(
        <div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">How does direct billing work?</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="text-md py-3 space-y-2">
                <p>You give us your policy number/ID and we submit it through the web portal. 
                The portal will give us the exact amount that your benefits plan pays; you may need to pay the remaining portion of the visit.</p>
            </div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">Will I need to pay anything?</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="text-md py-3 space-y-2">
                <p>You may need to pay the remainder of the visit fee, according to the portal reply. 
                For example, if you are covered for 80% of your 75$ visit fee, you will need to pay 15$ (the portal will “pay” the remaining 60$ to the clinic). 
                It all depends on your individual coverage and portal response.</p>
            </div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">What treatments can you direct bill for?</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="text-md py-3 space-y-2">
                <p>We can direct bill for Massage Therapy treatments. 
                Each individual plan may have different coverage with regards to each treatment type.</p>
            </div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">Can I check what my exact coverage is or how many visits I covered?</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="text-md py-3 space-y-2">
                <p>The submission portal is designed for clinics to create submissions in real time; it is not designed to check statements/limits/policies etc. 
                It works very similar to a credit card terminal – it can collect the payment but it can not check the card balance.</p>
                <p>If you want to check your coverage, remaining balance, deductible, or other benefits related policies, please contact your insurance provider directly.</p>
            </div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">What if my plan covers 100% of the treatment?</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="text-md py-3 space-y-2">
                <p>Congratulations. You do not need to pay anything extra at your appointment.</p>
            </div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">What if I have several insurance plans? Can you help to coordinate them?</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="text-md py-3 space-y-2">
                <p>Unfortunately, at that time we do not offer any coordination of benefits. We can only direct bill one plan. 
                You will have to contact the other plan provider on your own.</p>
            </div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">Do I get a receipt if I direct bill?</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="text-md py-3 space-y-2">
                <p>We can print the statement of direct billing submission to the portal, if you would like that.</p>
            </div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">What if you cannot process my direct billing, i.e. due to portal being down?</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="text-md py-3 space-y-2">
                <p>You will pay for your appointment the regular way i.e. e-transfer or cash. We will provide you with a receipt and you can submit it to your plan.</p>
            </div>

        </div>
    )
}