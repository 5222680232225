export const Footer = () => (
    <>
        <footer className="h-full w-full py-10 shadow-lg text-gray-500 font-medium text-md text-center">
            <div className="flex flex-col md:flex-row justify-center place-items-center space-y-5 md:space-x-20 ">
                <div className="flex mb-7">
                    <ul className = "flex flex-wrap flex-col">
                        <li className="pb-3 text-lg font-semibold text-[#ac5c84]">
                            CONTACT US
                        </li>
                        <div className = "border border-black border-opacity-10 p-4">
                            <li>
                                63 MCLEAN AVE, COLLINGWOOD,
                            </li>
                            <li>
                                ON, L9Y 2L3
                            </li>
                            <li>
                                TEL.: 905-737-5559
                            </li>
                            <li>
                                info@happyfamilywellness.ca
                            </li>
                        </div>
                    </ul>
                </div>
                <div className="flex">
                    <ul className = "flex flex-wrap flex-col">
                        <li className="pb-3 text-lg font-semibold text-[#ac5c84]">
                            OPENING TIMES
                        </li>
                        <div className = "border border-black border-opacity-10 p-4">
                            <li>
                                Monday : 9:30am - 7:30pm
                            </li>
                            <li>
                                Tuesday : 9:30am - 7:30pm
                            </li>
                            <li>
                                Wednesday : 9:30am - 7:30pm
                            </li>
                            <li>
                                Thursday : 9:30am - 7:30pm
                            </li>
                            <li>
                                Friday : 9:30am - 7:30pm
                            </li>
                            <li>
                                Saturday : 9:00am - 5:30pm
                            </li>
                            <li>
                                Sunday : By appointment
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
            <div className="text-sm pt-10">
                © Copyright 2015 Happy Family Wellness Clinic. All rights reserved.
            </div>
        </footer>
    </>
)