export const HotStoneMassage = () => {
    return(
        <div>
            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">Benefits of Hot Stone Massage</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="text-md py-3 space-y-2">
                <p>Hot stone masssage therapy benefits your mental, spiritual, physical, and overall wellness. It helps aid in relaxation of the mind and cures muscle soreness. 
                Smooth heated basalt stones are placed about your body then traditional massage strokes are applied using one heated stone after another. 
                The stones help to retain heat which then deeply penetrates into the muscles, releasing tension.</p>
            </div>
        </div>
    )
}