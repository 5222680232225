import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons" 
import { useState } from "react"

export const ServiceDropdown = ({title, message, show}) => {

    const [showMessage, setShowMessage] = useState(show)

    return (
        <div>
            <div>
                <button className="w-full py-3 border-[0.5px] border-gray-200 rounded-md text-left px-7 hover:shadow-lg text-lg font-semibold" onClick={() => setShowMessage(prevMessage => !prevMessage)}>
                    {!showMessage && (<FontAwesomeIcon icon={faCaretDown} className="pr-3 animate-pulse"/>)}
                    {showMessage && (<FontAwesomeIcon icon={faCaretUp} className="pr-3"/>)}
                    {title}
                </button>
                {showMessage && 
                (<div className="py-7 pb-0">
                    {message}
                </div>)}
            </div>
        </div>
    )
}