import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export const CompressionStockings = () => {
    return(
        <div>
            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">What Are Compression Stockings?</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="text-md py-3 space-y-2">
                <p>Compression stockings are specialized stockings that are worn to prevent poor blood circulation in the legs. 
                They prevent the pooling of blood in the veins and reduce the risk of deep vein thrombosis in the legs. They also help to enhance athletic performance.</p>
            </div>

            <text className="text-lg font-semibold">Helps With Common Medical Conditions Such As:</text>

            <div className="grid grid-cols-2 text-left text-md font-normal w-full py-3">
                <div>
                    <ul className="flex flex-col space-y-3 py-2">
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Venous disorders
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Edema
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Stasis
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Lymphedema
                        </li>

                    </ul>
                </div>
                <div>
                    <ul className="flex flex-col space-y-3 py-2">
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Skin changes
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Varicose veins and spider veins
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Conditions caused by pregnancy
                        </li>
                    </ul>
                </div>
            </div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">View Online Catalogs to See What’s Available!</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="flex flex-row w-full justify-center text-center space-x-1 md:space-x-5 p-5 text-[#ac5c84] md:text-lg hover:text-gray-200">
                <a href="https://www.venosan.ca/en/products/Rx-Swiss-Quality-c6370241" target="_blank" rel="noreferrer" className="hover:text-[#ac5c84] cursor-pointer">VENOSAN®</a>
                <a href="https://www.jobstcanada.com/products/medical-compression.html" target="_blank" rel="noreferrer" className="hover:text-[#ac5c84] cursor-pointer">JOBST®</a>                
                <a href="https://www.solidea.com/en" target="_blank" rel="noreferrer" className="hover:text-[#ac5c84] cursor-pointer">SOLIDEA®</a>
                <a href="https://www.lunatikathletiks.com/product-category/medical-compression-socks/" target="_blank" rel="noreferrer" className="hover:text-[#ac5c84] cursor-pointer">LUNATIK ATHLETIKS®</a>
            </div>

            <div className="text-center pb-5">
                <text className="text-md font-semibold text-center">All compression stockings and socks are custom ordered based on your specific leg measurements. 
                Our experienced and professional staff will ensure that all our patients get the compression wear that fits them perfectly.</text>
            </div>
            <div className="text-center">
                <text className="text-md font-light text-center">Medical compression socks and stockings are covered by most Extended Health Benefits plans. Check with your provider for details.</text>
            </div>
        </div>
    )
}