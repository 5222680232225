import React from 'react';
import { useEffect, useState } from 'react';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIsMobile } from "../hooks/IsMobileSize.hook"

export const Contact = () => {
    
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(JSON.parse(window.localStorage.getItem('show')));
      }, []);

    useEffect(() => {
        window.localStorage.setItem('show', show);
      }, [show]);

    if(show){
        setTimeout(() => {
            setShow(false)
          }, 100000);
    }

    const { isMobile } = useIsMobile()

    return (
    <div className='font-["Raleway"] bg-[#fffbfb] min-h-[100vh]'>
        <div className='flex md:flex-row flex-col w-full'>
            <div className='justify-center w-full'>                   
                <div className='pt-10 pb-12 text-center text-xl font-semibold'>
                    <p className='text-[#942d64]'>Work Hours</p>
                </div>
                <div className="px-8 text-xl text-center">
                    <div className="border rounded-lg">
                        <div className="flex flex-col divide-y text-md">
                            <div className="flex flex-row divide-x">
                                <div className="text-center py-2 font-semibold w-full"><text>Monday</text></div>
                                <div className="text-center py-2 w-full"><text>9:30am - 7:30pm</text></div>
                            </div>
                            <div className="flex flex-row divide-x">
                                <div className="text-center py-2 font-semibold w-full"><text>Tuesday</text></div>
                                <div className="text-center py-2 w-full"><text>9:30am - 7:30pm</text></div>
                            </div>
                            <div className="flex flex-row divide-x">
                                <div className="text-center py-2 font-semibold w-full"><text>Wednesday</text></div>
                                <div className="text-center py-2 w-full"><text>9:30am - 7:30pm</text></div>
                            </div>
                            <div className="flex flex-row divide-x">
                                <div className="text-center py-2 font-semibold w-full"><text>Thursday</text></div>
                                <div className="text-center py-2 w-full"><text>9:30am - 7:30pm</text></div>
                            </div>
                            <div className="flex flex-row divide-x">
                                <div className="text-center py-2 font-semibold w-full"><text>Friday</text></div>
                                <div className="text-center py-2 w-full"><text>9:30am - 7:30pm</text></div>
                            </div>
                            <div className="flex flex-row divide-x">
                                <div className="text-center py-2 font-semibold w-full"><text>Saturday</text></div>
                                <div className="text-center py-2 w-full"><text>9:00am - 5:30pm</text></div>
                            </div>
                            <div className="flex flex-row divide-x">
                                <div className="text-center py-2 font-semibold w-full"><text>Sunday</text></div>
                                <div className="text-center py-2 w-full"><text>By Appointment</text></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='justify-center w-full'>                   
                <div className='pt-10 pb-12 text-center text-xl font-semibold'>
                    <p className='text-[#942d64]'>Location</p>
                </div>
                {isMobile && <iframe 
                    title="mobile"
                    className='flex w-full'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2846.4095729084847!2d-80.2070422233591!3d44.486273898152916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882a7b94bc457d4b%3A0xf8716ec346ad4095!2s63%20McLean%20Ave%2C%20Collingwood%2C%20ON!5e0!3m2!1sen!2sca!4v1682301804161!5m2!1sen!2sca" 
                    width="300" 
                    height="250" 
                    style={{border: "0"}}
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>}

                {!isMobile && <iframe 
                    title="nonmobile"
                    className='flex'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2846.4095729084847!2d-80.2070422233591!3d44.486273898152916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882a7b94bc457d4b%3A0xf8716ec346ad4095!2s63%20McLean%20Ave%2C%20Collingwood%2C%20ON!5e0!3m2!1sen!2sca!4v1682301804161!5m2!1sen!2sca" 
                    width="500" 
                    height="350" 
                    style={{border: "0", margin: "auto"}}
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>}
            </div>
            <div className='justify-center w-full'>   
                <div className='pt-10 pb-5 text-center text-xl font-semibold'>
                    <p className='text-[#942d64]'>Contact Us</p>
                </div>
                <form className="px-8 mb-20 space-y-5" action="https://formsubmit.co/hfwc5559@gmail.com" method="POST" onSubmit={() => setShow(true)}>

                    <div>
                        <label for="name" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Name</label>
                        <input type="text" name="name" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required/>
                    </div>

                    <div>
                        <label for="email" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Email</label>
                        <input type="email" name="email" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required/>
                    </div>

                    <div>
                        <label for="subject" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Subject</label>
                        <input type="text" name="_subject" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required/>
                    </div>

                    <div>
                        <label for="message" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Your message</label>
                        <textarea name="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Leave a message..." required></textarea>
                    </div>

                    <input type="hidden" name="_next" value="http://happyfamilywellness.ca"/>
                    <input type="hidden" name="_captcha" value="false"/>

                    <div>
                        <button type="submit" className="text-white bg-[#cc98b4] hover:bg-[#942d64] focus:ring-4 focus:outline-none focus:ring-[#cc98b4] font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Submit</button>                    
                    </div>

                    {show && (
                        <div className="flex p-4 mb-4 text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400">
                            <div className="text-sm font-medium">
                                <p className='text-center'>Message sent, we will get back to you as soon as possible! </p>
                            </div>
                            <button type="button" className="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8" onClick={() => setShow(false)}>
                                <FontAwesomeIcon icon={faX} className='self-center w-full'></FontAwesomeIcon>
                            </button>
                        </div>
                    )}

                </form>
            </div>
        </div>
    </div>
    )
}
