import { ServiceDropdown } from '../components/ServiceDropdown';
import { Divider } from '../components/Divider';
import { MassageTherapy } from '../services/MassageTherapy';
import { PregnancyMassage } from '../services/PregnancyMassage';
import { HotStoneMassage } from '../services/HotStoneMassage';
import { Orthotics } from '../services/Orthotics';
import { CompressionStockings } from '../services/CompressionStockings';
import { useLocation } from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react';

export const Services = () => {
    const location = useLocation()
    const { from } = location.state
    const [massage] = useState(from === "massage"? true : false)
    const [pregnancy] = useState(from === "pregnancy"? true : false)
    const [hotstone] = useState(from === "hotstone" ? true : false)
    const [orthotics] = useState(from === "orthotics"? true : false)
    const [compression] = useState(from === "compression" ? true : false)
    const getPosMassage = useRef();
    const getPosPregnancy = useRef();
    const getPosHotStone = useRef();
    const getPosOrthotics = useRef();
    const getPosCompression = useRef();

    useEffect(() => {
        if(from === "massage"){
            window.scrollTo(0, getPosMassage.current.offsetTop)
        }
        if(from === "pregnancy"){
            window.scrollTo(0, getPosPregnancy.current.offsetTop)
        }
        if(from === "hotstone"){
            window.scrollTo(0, getPosHotStone.current.offsetTop)
        }
        if(from === "orthotics"){
            window.scrollTo(0, getPosOrthotics.current.offsetTop)
        }
        if(from === "compression"){
            window.scrollTo(0, getPosCompression.current.offsetTop)
        }
      }, [])

    return (
    <div className="flex justify-center bg-gradient-to-tr from-[#cc98b4] to-[#ebd8e1] min-h-[100vh] font-['Raleway']">
        <div className="flex flex-col md:w-[60%] w-screen bg-[#fffbfb] text-gray-700">
            <div className="flex flex-col md:mx-10 lg:mx-20 mx-5 py-10">

                <div className="pb-7">
                    <text className="text-xl font-bold">Services</text>
                    <div className="flex flex-row place-items-center">
                        <Divider className="w-[83px]"></Divider>
                    </div>
                </div>

                <div className="space-y-7">
                    <div ref={getPosMassage}>
                        <ServiceDropdown title={"Massage Therapy"} message={<MassageTherapy></MassageTherapy>} show={massage}></ServiceDropdown>
                    </div>
                    <div ref={getPosPregnancy}>
                        <ServiceDropdown title={"Pregnancy Massage"} message={<PregnancyMassage></PregnancyMassage>} show={pregnancy}></ServiceDropdown>
                    </div>
                    <div ref={getPosHotStone}>
                        <ServiceDropdown title={"Hot Stone Massage"} message={<HotStoneMassage></HotStoneMassage>} show={hotstone}></ServiceDropdown>
                    </div>
                    <div ref={getPosCompression}>
                        <ServiceDropdown title={"Compression Stockings"} message={<CompressionStockings></CompressionStockings>} show={compression}></ServiceDropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
