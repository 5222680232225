export const Insurance = () => {
    return(
        <div>

            <text className="text-lg">Happy Family Wellness Clinic offers Direct Billing to selected insurance companies at no extra charge.</text>

            <div className="py-3 space-y-2">
                <text className="text-md">We can bill your benefits plan in real time straight from the reception!! 
                It will save you time and effort to submit your receipts to your insurance provider, 
                and will reduce any upfront out of pocket expenses related to your treatment.</text>
            </div>

            <text className="text-lg font-semibold">We can direct bill the following insurance companies:</text>

            <div className="flex flex-row space-x-3 pt-3 text-md">
                <div className="border-l border-[2px] border-[#a44474]"></div>
                    <div>
                        <ul className="flex flex-col space-y-3 py-2 text-left">
                            <li>
                                Chambers of Commerce Group
                            </li>
                            <li>
                                Great West Life
                            </li>
                            <li>
                                Sun Life Fnancial
                            </li>
                            <li>
                                Manulife Financial
                            </li>
                            <li>
                                Cowan
                            </li>
                            <li>
                                Standard Life
                            </li>
                            <li>
                                Maximum Benefit
                            </li>
                            <li>
                                Johnson Inc
                            </li>
                            <li>
                                Industrial Alliance
                            </li>
                            <li>
                                Dejardins
                            </li>
                        </ul>
                    </div>
            </div>

            <div className="pt-5 font-semibold">
                <text className="text-lg">Please be aware that we </text>
                <text className="text-lg text-red-500">can not guarantee </text>
                <text className="text-lg">direct billing at every appointment due to issues with individual benefits plan or problems with submission portal.</text>
            </div>

        </div>
    )
}