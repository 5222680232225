import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import asta from "../images/asta.jpg"
import einar from "../images/einar.jpg"
import { Divider } from '../components/Divider'
import { useEffect } from 'react'

export const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
    <div className="flex justify-center bg-gradient-to-tr from-[#cc98b4] to-[#ebd8e1] min-h-[100vh] font-['Raleway']">
        <div className="flex flex-col md:w-[60%] w-screen bg-[#fffbfb] text-gray-700">
            <div className="flex flex-col md:mx-10 lg:mx-20 mx-5 py-10">
                <text className="text-xl font-bold">About Us</text>
                <Divider className="w-[88px]"></Divider>
                <text className="text-lg font-semibold pt-7">Committed To Cure The Root Cause Of The Problem</text>
                <text className="text-md font-normal py-3">We are committed to resolve the root of your problem. 
                For most soft tissue conditions including chronic lower back or neck pain, 
                we implement effective treatments to help patients rediscover health. 
                Manual techniques like Myofascial release, Trigger Point Therapy, 
                Lymphatic drainage  plus thorough physical examination allows you to better 
                resolve pain and cope up with your pain management. 
                We strive to offer the best options for pain relief and functional improvement.</text>
                <text className="text-md font-normal py-2"> Our overall goal: </text>
                <div className="grid grid-cols-2 text-left text-md font-normal w-full">
                    <div>
                        <ul className="flex flex-col space-y-3 py-2">
                            <li>
                                <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                                Optimize Health
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                                Increase Mobility
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul className="flex flex-col space-y-3 py-2">
                            <li>
                                <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                                Prevent Injury
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                                Educate on General Lifestyle Diseases
                            </li>
                        </ul>
                    </div>
                </div>
                <text className="text-lg font-semibold pt-7">Relieving Pain & Helping You Rediscover Health</text>
                <text className="text-md font-normal py-3">We understand that pain takes a heavy toll on your body. 
                If left untreated, it can turn into chronic pain and sap the juice out of your everyday life. 
                Your day to day life is hindered significantly. That is why we provide a wide range of services including:</text>
                <div className="grid grid-cols-2 text-left text-md font-normal w-full py-2">
                    <div>
                        <ul className="flex flex-col space-y-3 py-2">
                            <li>
                                <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                                Massage Therapy
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                                Physiotherapy
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                                Acupuncture
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul className="flex flex-col space-y-3 py-2">
                            <li>
                                <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                                Naturopathic Medicine
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                                Orthotics
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                                Medical Compression stockings
                            </li>
                        </ul>
                    </div>
                </div>
                <text className="text-lg font-medium pb-7 pt-5">At Happy Family Wellness Clinic, we offer a warm and loving environment that provides the necessary mind peace.
                You will not only experience relaxation but also rediscover a new approach to health and well being.</text>
                <text className="text-xl font-semibold">Meet Our Team</text>

                <div className="pb-5">
                    <Divider className="w-[145px]"></Divider>
                </div>
                
                <text className="text-xl font-medium pb-3">Asta Jokub RMT, DOmt</text>
                <div className="pb-5">
                    <img src={asta} alt="Asta" className="float-left drop-shadow-lg rounded-lg mr-5" width={200} height={200}/>

                    <div className="space-y-3 text-md">
                        <p>I chose to become an RMT because of the powerful ability that massage therapy has in helping people physically and emotionally. 
                        In this fast-paced world, I love helping my clients relax and resolve health problems. 
                        Giving and receiving positive energy from people is what motivates me to come to work 
                        each morning at my Collingwood massage therapy practice! </p>
                        
                        <p>In each patient encounter, my therapeutic goal is to provide both healing and a relaxing massage. 
                        Clinically, massage has been shown to increase circulation, improve respiration, ease digestion, 
                        and facilitate recovery from muscular injury and soreness. My clients frequently state that they 
                        also feel a relaxation of their body and mind and a calming of their spirit.</p>

                        <p>I graduated in 2010 from the Everest College of Massage Therapy and immediately began working in a variety of rehab and spa settings. 
                        I am skilled in hot stone and deep tissue massage. I frequently use trigger point therapy and myofascial release techniques to address any aching problems. 
                        By also integrating Swedish massage in my sessions, I am able to provide a relaxing and comfortable visit.</p>

                        <p>I look forward to meeting you! Everyone should include massage therapy as part of their wellness lifestyle. 
                        I hope to be a part of yours!</p>

                        <p className="text-md font-medium">Asta also continues to improve her skills in Osteopathy. She has extensive interest in pregnancy care. 
                        She is a graduate of Complementary Medicine College of Canada and is a Registered Member of the Ontario Association of Certified Osteopaths and Acupuncturists.</p>

                    </div>
                </div>    

                <text className="text-xl font-medium pb-3">Einar Jokub RMT, C.POD(I)</text>
                <div >
                    <img src={einar} alt="Einar" className="float-left drop-shadow-lg rounded-lg mr-5" width={200} height={200}/>

                    <div className="space-y-3 text-md">
                        <p>I have been interested in massage because of its physical, spiritual, and mental benefits and I am passionate about 
                        giving a quality massage that helps to heal and rejuvenate my clients. Each massage that I give is a unique experience, as well as a rewarding one. 
                        I strive to create an environment based on trust, mutual respect, and compassion.</p>
                        
                        <p>I specialize in Deep Tissue muscle treatments in conjunction with Muscle Energy, Contract – Relax, Myofascial Release, and Trigger Point therapy. 
                        By also integrating Swedish massage in my sessions, I am able to provide relaxing and comfortable visits. Therapeutic exercise and postural corrections 
                        after the massage will be advised when necessary to help you maintain your body’s natural form and alignment.</p>

                        <p>I look forward to meeting you! Everyone should include massage therapy as part of his or her wellness lifestyle. I hope to be a part of yours!</p>

                        <p className="text-md font-medium">Einar enjoys sports, active social-family activities, theatre, arts, and dreaming of their next family travel adventure. </p>

                    </div>
                </div>    

            </div>
        </div>
    </div>
    )
}
