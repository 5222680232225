import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export const PregnancyMassage = () => {
    return(
        <div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">How Massage Will Benefit You During Your Pregnancy</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="text-md py-3 space-y-2">
                <p>Modern investigation and research is proving that prenatal massage therapy can be a very instrumental ingredient in women’s prenatal care.
                There are many ways expectant mothers can benefit from a prenatal pregnancy massage. Studies have proven that massage therapy during pregnancy can help lower anxiety, 
                reduce symptoms of depression, relieve muscle aches and joint pains and even enhance labour outcomes and the health of your newborn.</p>
            </div>

            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
            <text className="text-lg font-semibold">Hormonal Regulation:</text>
            <div className="text-md py-3 space-y-2">
                <p>Hormone levels associated with relaxation are increased and stress are significantly decreased, leading to mood regulation and improved cardiovascular health, 
                when massage therapy is introduced to a woman’s prenatal care.These changes in hormone levels also lead to fewer complications during birth and fewer instances of 
                newborn complications, such as low birth weight.</p>
            </div>

            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
            <text className="text-lg font-semibold">Reduction of Swelling</text>
            <div className="text-md py-3 space-y-2">
                <p>Edema, or swelling of the joints during pregnancy, is often caused by reduced circulation and increased pressure on the major blood vessels by the heavy uterus. 
                Massage helps to stimulate soft tissues to reduce collection of fluids in swollen joints, which also improves the removal of tissue waste, carried by the body’s lymph system.</p>
            </div>

            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
            <text className="text-lg font-semibold">Improvement Of Nerve Pain:</text>
            <div className="text-md py-3 space-y-2">
                <p>During the late stages of pregnancy the uterus will rest on the muscles of the pelvic floor and lower back. This puts tension on the muscles of the upper and lower leg, 
                which causes them to swell and exert pressure on nearby nerves. Prenatal pregnancy massage will help alleviate this tension and in most cases result in a significant reduction 
                in sciatic nerve pain.</p>
            </div>

            <text className="text-lg font-semibold">Other Benefits of Prenatal Massage:</text>

            <div className="grid grid-cols-2 text-left text-md font-normal w-full py-3">
                <div>
                    <ul className="flex flex-col space-y-3 py-2">
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Reduced back pain
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Reduced joint pain
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Improved circulation
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Reduced edema
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Reduced muscle tension and headaches
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Reduced stress, anxiety and depression, improved maternal mood
                        </li>
                    </ul>
                </div>
                <div>
                    <ul className="flex flex-col space-y-3 py-2">
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Improved oxygenation of soft tissues and muscles
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Promotes better sleep, less disturbances
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Decreased obstetrical complications
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Improve neonatal health and development
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Relaxes muscle knots that cause cramping, tightening, and stiffness
                        </li>
                    </ul>
                </div>
            </div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">Common Concerns About Pregnancy Massage</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>
            <div className="text-md py-3 space-y-2">
                <p>You and your baby’s well-being comes first. Massage Therapists are professionally trained to work with you to develop an effective and safe massage therapy treatment plan 
                during your pregnancy that will complement the care you are receiving from your doctor and other healthcare providers. Ergonomic cushions and supports are used to ensure your 
                safety and comfort during your massage therapy treatment. Massage Therapists are also prepared to address the subtle needs of expectant mothers like yourself, including the 
                potential need for frequent restroom breaks and the use of unscented massage oil so as to not aggravate a sensitive sense of smell. Good communication with your therapist will 
                result in the most beneficial and rewarding pregnancy-massage therapy for you and your baby.</p>
            </div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">Post-Partum Pregnancy Massage</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>
            <div className="text-md py-3 space-y-2">
                <p>Massage is also good after giving birth. Post-partum pregnancy massage helps  to restore a mother’s body to its pre-pregnancy condition. 
                It helps to realign the body weight, and tones the over-stretched skin over the belly. As with all massage, it relieves muscle tension and stress from mothering duties!</p>
            </div>

        </div>
    )
}