import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export const MassageTherapy = () => {
    return(
        <div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">Why Choose Us?</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="text-md py-3 space-y-2">
                <p>We are dedicated to addressing the root causes of your health concerns. Our expertise extends to various soft tissue conditions, 
                    including tendinitis, sprains, strains, chronic lower back or neck pain, and tension headaches. Through advanced manual techniques such as Myofascial release, 
                    Trigger Point Therapy, and Lymphatic drainage, coupled with a thorough physical examination, we strive to provide effective solutions for pain relief and improved functionality.</p>
                <p>
                    At Happy Family Wellness Clinic, we strive to create a nurturing environment that fosters peace of mind. Our goal is not only to offer relaxation but also to introduce a fresh approach to health and well-being.
                </p>
            </div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">Are You Suffering From Any Of The Following?</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>

            <div className="grid grid-cols-2 text-left text-md font-normal w-full py-3">
                <div>
                    <ul className="flex flex-col space-y-3 py-2">
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Back pain
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Headaches & migraines
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Muscle strains, spasms & sprains
                        </li>
                    </ul>
                </div>
                <div>
                    <ul className="flex flex-col space-y-3 py-2">
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Stress-related conditions
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Inflammatory conditions such as arthritis
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheck} className="pr-2"></FontAwesomeIcon>
                            Post-injury & post-surgical rehabilitation
                        </li>
                    </ul>
                </div>
            </div>

            <text className="text-lg font-semibold">If yes, then get your massage therapy at our clinic in Collingwood.</text>

            <div className="text-md py-3 space-y-2">
                <p>Massage Therapy plays a crucial role in helping patients enjoy a healthy lifestyle. 
                Our skilled and trained therapists not only offer relaxation and preventative massage, 
                they also provide effective hands on relief for various health issues.</p>
                <p>If you are looking for a massage therapist in Collingwood, you will find that our practitioners 
                have excellent clinical and listening skills, and that they make sure to meet your unique health goals and treatment needs.</p>
            </div>

            <div className="flex flex-row items-center space-x-2">
                <text className="text-lg font-semibold">How Does Massage Therapy Work?</text>
                <div className ="flex-grow border-b-[1px] border-gray-400"></div>
            </div>
            <div className="text-md py-3 space-y-2">
                <p>Massage Therapy improves circulation in the body by bringing oxygen & nutrients to the tissues. 
                It alleviates muscle tension & pain, improves flexibility & mobility, and helps to eliminate lactic acid 
                and other waste by-products from the body, which in turn minimizes muscle pain & stiffness. Massage therapy 
                also helps in relieving stress by reducing levels of stress hormones such as cortisol.</p>
            </div>

        </div>
    )
}