import { Link } from "react-router-dom";
import googlereviews from "../images/google-reviews.png";
import asta from "../images/asta.jpg";
import einar from "../images/einar.jpg";
import winner from "../images/winneredited.png";
import rmtao from "../images/RMTAO.png";
import billing from "../images/Direct-Billing.png";
import gift from "../images/gift.jpg";
import massagepic from "../images/massage.jpg"
import pregnancy from "../images/pregnancy.jpg"
import hotstone from "../images/hotstone.jpg"
import orthotics from "../images/orthotics.jpg"
import stockings from "../images/stockings.jpg"
import { faQuoteLeft} from "@fortawesome/free-solid-svg-icons";
import { faLocationDot} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Carousel from "../components/Carousel";
import { Divider } from '../components/Divider'
import { DividerFlipped } from "../components/DividerFlipped";
import { useIsMobile } from "../hooks/IsMobileSize.hook"

export const Home = () => {

    const slides = [massagepic, billing, gift]
    const massageimg = {backgroundImage: `url(${massagepic})`}
    const pregnancyimg = {backgroundImage: `url(${pregnancy})`}
    const hotstoneimg = {backgroundImage: `url(${hotstone})`}
    const orthoticsimg = {backgroundImage: `url(${orthotics})`}
    const stockingsimg = {backgroundImage: `url(${stockings})`}
    const { isMobile } = useIsMobile()

return (
    <>
    <div className="flex flex-col">
        <section className="flex md:flex-row flex-col md:min-h-[70vh] bg-slate-100">

            <div className="md:w-[50vw] md:pt-[20vh] md:py-0 py-10">
                <div className="text-[#942d64] pl-[5%] pr-[5%] md:pl-[10%] md:pr-[25%]">
                    <p className="text-2xl md:text-4xl pb-2 font-Raleway">Healthy Family, Happy Family</p>
                    <p className="text-xl md:text-2xl font-Montserrat font-light">Experience the Benefits of Massage Therapy Together at Happy Family Wellness Clinic.</p>
                    <div className="py-5">
                        <Link to="/booking">
                            <button className="font-Montserrat animate-pulse transition ease-in-out hover:animate-none hover:-translate-y-1 text-xl md:text-2xl text-white rounded-2xl py-2 px-4 bg-gradient-to-r from-[#be7e9e] to-[#cc98b4] hover:shadow-lg">Request Appointment</button>
                        </Link>
                    </div>
                    <p className="md:text-xl font-light font-Montserrat">Call us at: 905-737-5559</p>
                </div>
            </div> 

            <div className="md:w-[50vw]">
                <Carousel autoSlide={true} autoSlideInterval={5000}>
                    {slides.map((s) => (
                        <img src={s} className="object-fit md:h-[70vh] w-full"/>
                    ))}
                </Carousel>
            </div>

        </section>
        
        <section className="md:py-10 bg-slate-200">
            <div className="flex flex-row w-full justify-center relative">
                <div className="md:pt-[130px] md:pb-[50px] w-0 md:w-full">
                    <div className="bg-[#cc98b4] md:h-[535px]"></div>
                </div>
                <div className="md:w-[650px] py-[50px] md:absolute text-white text-left">
                    <p className="text-center text-2xl md:text-4xl bg-[#942d64] py-5 px-5 font-Raleway">Our Mission</p>
                    <div className="font-Montserrat font-light">
                        <p className="text-lg md:text-xl bg-[#cc98b4] pt-7 py-2 px-10">At Happy Family Wellness Clinic we provide natural healthcare treatments 
                        including, massage therapy, acupuncture and medical compression stockings.</p>
                        <p className="text-lg md:text-xl bg-[#cc98b4] py-2 px-10">We are experienced practitioners  and have in depth knowledge to deliver 
                        the right relief from pain through proven and effective methods.</p>
                        <p className="text-lg md:text-xl bg-[#cc98b4] py-2 px-10">The comfortable and caring environment at our clinic is the ideal place 
                        to relax and be immersed in a holistic treatment. We aim to help you achieve prime health condition with exemplary health care service.</p>
                    </div>
                    <div className="py-5 bg-[#cc98b4] flex flex-row w-full justify-center md:space-x-16">
                        <img src={winner} alt="award" className="md:block hidden" height={150} width={150}/>
                        <img src={rmtao} alt="rmtao" className="md:block hidden" height={121} width={231}/>
                        <img src={winner} alt="award" className="md:hidden flex" height={150} width={150}/>
                        <img src={rmtao} alt="rmtao" className="md:hidden flex" height={101} width={211}/>
                    </div>
                </div>
                <div className="md:py-[50px] md: w-0 md:w-full">
                    <div className="bg-[#942d64] md:h-[550px]"></div>
                </div>
            </div>
        </section>

        <section className="bg-slate-100 pb-20">

            <div>
                <p className="text-center text-2xl md:text-4xl py-5 px-5 font-Raleway">
                    About Us
                </p>
            </div>

            <div className="px-[15%] font-Montserrat">

                <div className="">
                    <text className="text-lg md:text-xl font-medium flex md:justify-start justify-center">Asta Jokub RMT</text>
                    <Divider className="md:w-[240px] w-[500px]"></Divider>
                </div>

                <div className="flex md:flex-row flex-col pt-5">

                    <div className="md:w-[30%] w-full md:block flex justify-center">
                        <img src={asta} alt="Asta" className="md:block hidden drop-shadow-lg mr-auto pr-5" width={300} height={300}/>
                        <img src={asta} alt="Asta" className="md:hidden block drop-shadow-lg mb-5" width={200} height={200}/>
                    </div>

                    <div className="md:w-full">
                        <div className="space-y-3 text-lg md:text-justify">

                            <p>I became a Registered Massage Therapist driven by the profound impact massage therapy has on people both physically and emotionally. 
                            In our fast-paced world, I find joy in helping clients relax and address health issues. 
                            The exchange of positive energy with my clients motivates me each morning at my Collingwood massage therapy practice. </p>
                            
                            <p>A 2010 graduate from Everest College of Massage Therapy, I have worked in rehab and spa settings I learned how to incorporate hot stone, 
                            deep tissue and Swedish massage for a comprehensive and comfortable experience.</p>

                            <p>Including massage therapy in your wellness lifestyle is crucial. I look forward to being a part of yours!</p>

                        </div>
                    </div>

                </div>

                <div className="pt-10">
                    <text className="text-lg md:text-xl font-medium flex md:justify-end justify-center">Einar Jokub RMT</text>
                    <DividerFlipped className="md:w-[270px] w-[500px]"></DividerFlipped>
                </div>

                <div className="flex md:flex-row flex-col pt-5">

                    <div className="w-full md:hidden flex justify-center pb-5">
                        <img src={einar} alt="Einar" className="drop-shadow-lg" width={200} height={200}/>
                    </div>

                    <div className="md:w-full">
                        <div className="space-y-3 text-lg md:text-justify">
                            <p>I've been drawn to massage for its holistic benefits – physical, spiritual, and mental. My passion lies in delivering quality massages that heal and rejuvenate my clients. 
                            Each massage is a unique and rewarding experience, fostering an environment built on trust, mutual respect, and compassion.</p>
                            
                            <p>Specializing in Deep Tissue muscle treatments alongside Muscle Energy, Contract – Relax, Myofascial Release, and Trigger Point therapy, I offer a comprehensive approach. 
                            Integrating Swedish massage ensures a relaxing and comfortable visit. When needed, I provide therapeutic exercise and postural corrections for maintaining your body's natural form and alignment.</p>

                            <p>I eagerly anticipate meeting you! Including massage therapy in your wellness lifestyle is essential, and I hope to contribute to yours.</p>

                        </div>
                    </div>

                    <div className="w-[30%] md:block flex justify-center">
                        <img src={einar} alt="Einar" className="md:block hidden drop-shadow-lg ml-auto pl-5" width={300} height={300}/>                  
                    </div>

                </div>

            </div>

        </section>
        
        <section>
            <div>
                <p className="text-center text-2xl md:text-4xl bg-slate-200 py-5 px-5 font-Raleway">Services We Offer</p>
            </div>
            <div className="bg-slate-200 font-Montserrat pb-20 pt-10">
                <div className="flex md:flex-row flex-col justify-center items-center px-10 md:space-x-10 md:space-y-0 space-y-10">

                    <div className="flex items-center justify-center w-full md:h-[300px] h-[150px] bg-center bg-cover bg-blend-overlay bg-black bg-opacity-30 hover:bg-opacity-25 shadow-[#ff2097]" style={massageimg}>
                        <div className="text-center">
                            <Link to="/services" state={{from:"massage"}}>
                                <button className="animate-pulse-slow transition ease-in-out hover:animate-none hover:-translate-y-1 text-2xl bg-slate-200 bg-opacity-70 text-[#942d64] rounded-2xl py-2 px-4 hover:shadow-2xl">Massage Therapy</button>
                            </Link>
                        </div>
                    </div>

                    <div className="flex items-center justify-center w-full md:h-[300px] h-[150px] bg-center bg-cover bg-blend-overlay bg-black bg-opacity-30 hover:bg-opacity-25 shadow-[#ff2097]" style={pregnancyimg}>
                        <div className="text-center">
                            <Link to="/services" state={{from:"pregnancy"}}>
                                <button className="animate-pulse-slow transition ease-in-out hover:animate-none hover:-translate-y-1 text-2xl bg-slate-200 bg-opacity-70 text-[#942d64] rounded-2xl py-2 px-4 hover:shadow-2xl">Pregnancy Massage</button>
                            </Link>
                        </div>
                    </div>

                    <div className="flex items-center justify-center w-full md:h-[300px] h-[150px] bg-center bg-cover bg-blend-overlay bg-black bg-opacity-30 hover:bg-opacity-25 shadow-[#ff2097]" style={hotstoneimg}>
                        <div className="text-center">
                            <Link to="/services" state={{from:"hotstone"}}>  
                                <button className="animate-pulse-slow transition ease-in-out hover:animate-none hover:-translate-y-1 text-2xl bg-slate-200 bg-opacity-70 text-[#942d64] rounded-2xl py-2 px-4 hover:shadow-lg">Hot Stone Massage</button>
                            </Link>
                        </div>
                    </div>

                    <div className="flex items-center justify-center w-full md:h-[300px] h-[150px] bg-center bg-cover bg-blend-overlay bg-black bg-opacity-30 hover:bg-opacity-25 shadow-[#ff2097]" style={stockingsimg}>
                        <div className="text-center">
                            <Link to="/services" state={{from:"compression"}}>  
                                <button className="animate-pulse-slow transition ease-in-out hover:animate-none hover:-translate-y-1 text-2xl bg-slate-200 bg-opacity-70 text-[#942d64] rounded-2xl py-2 px-4 hover:shadow-lg">Compression Stockings</button>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <section className="w-full bg-slate-100 pb-10">
            <div>
                <p className="text-center text-2xl md:text-4xl py-5 px-5 font-Raleway">Contact Us</p>
            </div>
            <div className="flex md:flex-row flex-col md:space-x-10 px-10 font-Montserrat text-white">
                <div className="md:w-[50%] bg-[#cc98b4]">
                    <div className="md:mx-auto ml-14 text-left md:max-w-[40%] py-10 space-y-3">
                        <h1 className="text-2xl">Location</h1>
                        <div className="flex flex-row space-x-3">
                            <div className="flex items-center">
                                <FontAwesomeIcon icon={faLocationDot} className="fa-2xl"/>
                            </div>
                            <div>
                                <p className="text-lg">63 Mclean ave</p>
                                <p className="text-lg">Collingwood, ON</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full'>                   
                    {isMobile && <iframe 
                        title="mobile"
                        className='flex w-full'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2846.4095729084847!2d-80.2070422233591!3d44.486273898152916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882a7b94bc457d4b%3A0xf8716ec346ad4095!2s63%20McLean%20Ave%2C%20Collingwood%2C%20ON!5e0!3m2!1sen!2sca!4v1682301804161!5m2!1sen!2sca" 
                        width="300" 
                        height="250" 
                        style={{border: "0"}}
                        allowfullscreen="" 
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>}

                    {!isMobile && <iframe 
                        title="nonmobile"
                        className='flex'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2846.4095729084847!2d-80.2070422233591!3d44.486273898152916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882a7b94bc457d4b%3A0xf8716ec346ad4095!2s63%20McLean%20Ave%2C%20Collingwood%2C%20ON!5e0!3m2!1sen!2sca!4v1682301804161!5m2!1sen!2sca" 
                        width="100%" 
                        height="250" 
                        style={{border: "0", margin: "auto"}}
                        allowfullscreen="" 
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>}
                </div>
            </div>
        </section>
        
        <section className="bg-slate-200 py-20 px-[9%] font-Montserrat">
            <div className="grid flex-row space-y-5 md:space-y-0 md:grid-cols-3 text-left pb-10">
                <div className="justify-center text-lg md:text-xl px-[10%] md:px-[15%]">
                    <FontAwesomeIcon icon={faQuoteLeft} className="fa-xl pb-2"/>  
                    <p>I have started my weekly massage sessions with Einar and found it very useful for the tight areas of my lower back, neck and hip. 
                    Einar knows how to use different techniques and he knows how to deal with spasms so deep and gently! I strongly recommend everyone to 
                    try Happy Family Clinic which is a great place for massage. Always clean with professional staff.</p>
                    <p className="italic pt-5">- Nilou</p>
                </div>
                <div className="justify-center text-lg md:text-xl px-[10%] md:px-[15%]">
                    <FontAwesomeIcon icon={faQuoteLeft} className="fa-xl pb-2"/>
                    <p>Came in to have a massage with Asta, and felt less pain! I have been suffering with low back pain all my life and also had treatment with the Physiotherapist. 
                    Great results,great people working in the clinic.</p>
                    <p className="italic pt-5">- Elio</p>
                </div>
                <div className="justify-center text-lg md:text-xl px-[10%] md:px-[15%]">
                    <FontAwesomeIcon icon={faQuoteLeft} className="fa-xl pb-2"/>
                    <p>I had a pinched sciatic nerve in my hip. I was barely walking for the excruciating pain. My daughter recommended Einar. 
                    At first I was reluctant to try massage therapy. Einar made me feel comfortable and knew exactly what to do to make me feel better. 
                    He truly is a miracle worker. Einar you have precious hands. I am no longer limping. I can now work properly without any pain.</p>
                    <p className="italic pt-5">- Carmela</p>
                </div>
            </div>
            <div className="pt-10 text-center">
                <text className="text-xl font-semibold">To see more reviews like these or leave a review of your own visit our clinic on Google</text>
            </div>
            <div className="text-center text-xl pt-7 flex justify-center">
                <a target="_blank" rel="noreferrer" href="https://www.google.com/search?q=happy+family+wellness&sxsrf=AJOqlzVxIj7PdoqCBzmZdlTqF3bxMue0-A%3A1678417634673&source=hp&ei=4p4KZKOCJ_mkkPIPsriCmAk&iflsig=AK50M_UAAAAAZAqs8kCzm_MDS8sf9YE--KgEXLcizExC&oq=&gs_lcp=Cgdnd3Mtd2l6EAEYATIHCCMQ6gIQJzIHCCMQ6gIQJzIHCCMQ6gIQJzIHCCMQ6gIQJzIHCCMQ6gIQJzIHCCMQ6gIQJzIHCCMQ6gIQJzIHCCMQ6gIQJzIHCCMQ6gIQJzIHCCMQ6gIQJ1AAWABgugdoAXAAeACAAQCIAQCSAQCYAQCwAQo&sclient=gws-wiz#lrd=0x882b2a26384c2f8b:0xca6babe039f7dc91,1,,,,">
                    <button className="transition ease-in-out hover:-translate-y-1 hover:shadow-lg text-xl md:text-xl bg-white bg-opacity-70 text-black flex items-center border-2 border-slate-200 p-3 rounded-lg">
                        <img src={googlereviews} alt="Google Reviews" width={200} height={200}/>
                    </button>
                </a>
            </div>
        </section>
    </div>
    </>
    )
}

